<template>
  <div class="root">
    <!-- 1.头部 -->
    <div class="title">
      <div class="name">
        <span @click="$router.back(-1)" :style="{cursor:'pointer',fontSize:'15px'}">
          <i class="el-icon-arrow-left">返回</i>
        </span>
      </div>
      <div class="fr">
        <el-date-picker value-format="YYYY-MM-DD" v-model="dateDefaultValue" type="daterange" clearable unlink-panels
          range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" @change="dateChange" />
      </div>
    </div>
    <!-- 2.图表 -->
    <div id="myChart1"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { checkDetailAll } from '@/api/dashboard/userNumberCount'
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj)
export default {
  name: 'ChartDetail',
  data() {
    return {
      dateDefaultValue: [], //默认日期
      // 折线图图例
      nameList: [
        '呼叫次数',
        '接听次数',
        '分钟数(分钟)',
        '通话费(元)',
        '取整分钟',
        '取整利润',
        '成本通话费(元)',
        '利润(元)',
      ],
      chartInstance: null, //图表实例

      originalMinutesList: [], //取整分钟
      roundPriceList: [], //取整利润
      billNumList: [], //呼叫次数
      callNumList: [], //接听次数
      callTimeList: [], //分钟数(分钟)
      costPriceList: [], //成本通话费(元)
      callChargeList: [], //通话费(元)
      callProfitList: [], //利润(元)
      //日期条件
      search_form: {
        dateBegin: '',
        dateEnd: '',
      },
      bottomData: [], //下表日期数据集合
      allData: [], //所有图例数据集合
      //半透明颜色值
      colorArr1: [
        'rgba(11,168,44,0.5)',
        'rgba(44,110,255,0.5)',
        'rgba(22,242,217,0.5)',
        'rgba(254,33,30,0.5)',
        'rgba(254,33,30,0.5)',
        'rgba(250,205,0,0.5)',
        'rgba(44,110,255,0.5)',
        'rgba(22,242,217,0.5)',
      ],
      //全透明颜色值
      colorArr2: [
        'rgba(11,168,44,0)',
        'rgba(44,110,255,0)',
        'rgba(22,242,217,0)',
        'rgba(254,33,30,0)',
        'rgba(254,33,30,0)',
        'rgba(250,205,0,0)',
        'rgba(44,110,255,0)',
        'rgba(22,242,217,0)',
      ],
    }
  },
  mounted() {
    this.search_form.dateBegin = this.$route.query.dateBegin
    this.search_form.dateEnd = this.$route.query.dateEnd
    //设置默认日期
    this.dateDefaultValue = [
      this.search_form.dateBegin,
      this.search_form.dateEnd,
    ]
    this.getData()
    this.initChart()
  },
  methods: {
    //初始化图表数据
    initChart() {
      //初始化时销毁图表实例，避免重复渲染
      echarts.init(document.getElementById('myChart1')).dispose()
      //获取实例
      this.chartInstance = echarts.init(document.getElementById('myChart1'))
      //初始化静态数据
      const initOption = {
        legend: {
          left: 150,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        axisPointer: {
          type: 'cross',
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          axisPointer: {
            type: 'line',
            snap: true,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        series: [],
      }
      //调用实例
      unwarp(this.chartInstance).setOption(initOption)
    },
    // 获取折线图数据
    getData() {
      checkDetailAll({
        ...this.search_form,
      }).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.message)
          return false
        } else {
          this.bottomData = []
          res.data.forEach((item) => {
            //下表标日期数据
            this.bottomData.unshift(item.date)
            //呼叫次数
            if (item.billNum == null) {
              this.billNumList.unshift(0)
            } else {
              this.billNumList.unshift(item.billNum)
            }
            //接听次数
            if (item.callNum == null) {
              this.callNumList.unshift(0)
            } else {
              this.callNumList.unshift(item.callNum)
            }
            // 分钟数(分钟)
            if (item.callTime == null) {
              this.callTimeList.unshift(0)
            } else {
              this.callTimeList.unshift(item.callTime)
            }
            // 成本通话费(元)
            if (item.costPrice == null) {
              this.costPriceList.unshift(0)
            } else {
              this.costPriceList.unshift(item.costPrice)
            }
            // 通话费(元)
            if (item.callCharge == null) {
              this.callChargeList.unshift(0)
            } else {
              this.callChargeList.unshift(item.callCharge)
            }
            // 利润(元)
            if (item.callProfit == null) {
              this.callProfitList.unshift(0)
            } else {
              this.callProfitList.unshift(item.callProfit)
            }
            // 取整分钟
            if (item.originalMinutes == null) {
              this.originalMinutesList.unshift(0)
            } else {
              this.originalMinutesList.unshift(
                parseInt(item.callTime) - parseInt(item.originalMinutes)
              )
            }
            // 取整利润
            if (item.roundPrice == null) {
              this.roundPriceList.unshift(0)
            } else {
              this.roundPriceList.unshift(item.roundPrice)
            }
          })
          //图例数据集合，按照图例顺序排列
          this.allData = [
            this.billNumList,
            this.callNumList,
            this.callTimeList,
            this.callChargeList,
            this.originalMinutesList,
            this.roundPriceList,
            this.costPriceList,
            this.callProfitList,
          ]
          //更新图表数据
          this.updateChart()
        }
      })
    },
    //更新图表数据
    updateChart() {
      const seriesData = []
      //seriesData动态赋值
      this.allData.forEach((item, i) => {
        seriesData.push({
          name: this.nameList[i],
          type: 'line',
          data: this.allData[i],
          smooth: true,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: this.colorArr1[i] },
              { offset: 1, color: this.colorArr2[i] },
            ]),
          },
        })
      })
      const updateOption = {
        xAxis: {
          data: this.bottomData,
        },
        series: seriesData,
      }
      unwarp(this.chartInstance).setOption(updateOption)
    },
    //修改日期
    dateChange(dateValue) {
      //获取日期参数重新请求数据
      this.search_form.dateBegin = dateValue[0]
      this.search_form.dateEnd = dateValue[1]
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .name {
      width: 100px;
    }
    .fr {
      width: 30%;
    }
  }
  #myChart1 {
    height: 90%;
    width: 100%;
  }
}
</style>
